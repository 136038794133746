// eslint-disable-next-line import/order
import { store } from "services/store"

import moment from "moment"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"
import { CustomProvider } from "rsuite"

import { App } from "App"
import { AppContextProvider } from "contexts/AppContexts"

import "./styles/index.less"

import "./styles/index.scss"
import "./styles/rs-default-theme.scss"
import "./styles/patterns.scss"
import "moment/locale/fr"

moment.locale("fr")

const ponponMenuRoot = ReactDOM.createRoot(document.getElementById("root")!)

ponponMenuRoot.render(
  <Provider store={store}>
    <CustomProvider theme="dark">
      <AppContextProvider>
        <App />
      </AppContextProvider>
    </CustomProvider>
  </Provider>,
)
