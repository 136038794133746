import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Input, InputGroup } from "rsuite"

import { FontIcon } from "components/FontIcon/FontIcon"
import { useAuthenticate } from "hooks/useAuthenticate"

export function Frontpage() {
  const { authenticate, isLogging } = useAuthenticate()

  const [invitationCode, setInvitationCode] = useState("")
  const [error, setErrors] = useState<string[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    setErrors([])
  }, [invitationCode])

  return (
    <div className="relative fullWidth fullHeight flex center alignCenter">
      <div className="flex column center alignCenter">
        <div className="fontSize30">
          Ponpon<span style={{ color: "#e5a00c" }}>.tv</span>
        </div>

        <Button
          loading={isLogging}
          style={{ width: 200, marginTop: 20 }}
          onClick={async () => {
            await authenticate()
          }}
          appearance="primary"
        >
          Connection
        </Button>

        <InputGroup inside style={{ width: 200, marginTop: 10 }}>
          <Input
            value={invitationCode}
            onChange={setInvitationCode}
            placeholder="Code d'invitation"
            maxLength={6}
            minLength={6}
          />
          <InputGroup.Button
            style={{ backgroundColor: "#c48b06", marginRight: -1 }}
            onClick={() => navigate(`/invitation/${invitationCode}`)}
          >
            <FontIcon icon="circle-right" />
          </InputGroup.Button>
        </InputGroup>
      </div>
    </div>
  )
}
