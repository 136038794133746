import { Button, TagInput } from "rsuite"

import api from "api"
import { FontIcon } from "components/FontIcon/FontIcon"
import { cellDate, cellString, Table } from "components/Table"
import { Invitation } from "types/Invitation"

import { InvitationForm } from "./InviationsForm"

export function Invitations() {
  const { data: invitations = [] } = api.invitations.getAll()
  const [deleteInvitation] = api.invitations.delete()

  return (
    <div className="fullWidth fullHeight flex column">
      <div className="fullWidth flex center alignEnd">
        <InvitationForm style={{ marginLeft: "auto", marginBottom: 10 }} />
      </div>
      <Table<Invitation>
        data={invitations}
        columns={[
          {
            grow: 3,
            title: "Créé par",
            dataKey: "invitedBy",
            cell: (user) => {
              return cellString(user.email)
            },
          },
          {
            grow: 3,
            title: "Date d'invitation",
            dataKey: "invitedOn",
            cell: cellDate,
          },
          {
            grow: 3,
            title: "Code d'invitation",
            dataKey: "invitCode",
            cell: cellString,
          },
          {
            grow: 3,
            title: "Invitation acceptée",
            dataKey: "accepted",
            cell: (value) => cellString(value ? "Oui" : "Non"),
          },
          {
            grow: 5,
            title: "E-mails autorisés",
            dataKey: "emailsToInvite",
            cell: (value: string[]) => (
              <TagInput value={value} readOnly style={{ border: "none" }} />
            ),
          },
          {
            grow: 1,
            title: "Actions",
            dataKey: "acceptedOn",
            accessor: (_) => _,
            cell: (value) => {
              return (
                <div className="flex end alignCenter fullWidth fullHeight ">
                  <Button
                    appearance="primary"
                    className="marginRight10"
                    onClick={() => {
                      deleteInvitation(value.id)
                    }}
                  >
                    <FontIcon icon="trash" />
                  </Button>
                </div>
              )
            },
          },
        ]}
        fillHeight
        renderEmpty={() => (
          <div className="fullWidth fullHeight flex center alignCenter">
            Pas d'invitation disponible
          </div>
        )}
      />
    </div>
  )
}
