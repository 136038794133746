import { useSelector } from "react-redux"
import { createBrowserRouter, RouterProvider } from "react-router-dom"

import { useErrorsHandling } from "hooks/useErrorsHandling"
import { RootState } from "services/store"
import { Error404 } from "views/Errors/Error404"
import { Frontpage } from "views/Frontpage"
import { Interface } from "views/Interface"
import { Requests } from "views/Requests/Requests"
import { Search } from "views/Search/Search"

import "styles/index.scss"

export function App() {
  useErrorsHandling()

  const { me } = useSelector((state: RootState) => state.auth)

  const router = createBrowserRouter([
    {
      path: "/",
      errorElement: <Error404 />,
      children: [
        me
          ? {
              path: "/",
              element: <Interface />,
              errorElement: <Error404 />,
              children: [
                {
                  path: "requests",
                  element: <Requests />,
                },
                {
                  path: "search",
                  element: <Search />,
                },
              ],
            }
          : {
              path: "/",
              element: <Frontpage />,
              errorElement: <Error404 />,
              children: [],
            },
      ],
    },
  ])

  return (
    <RouterProvider
      router={router}
      future={{
        v7_startTransition: true,
      }}
    />
  )
}
