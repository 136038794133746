import { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import { Button, Input, Loader, Nav } from "rsuite"

import request from "api/request"
import { CellContent } from "components/Content/CellContent"
import { Page } from "components/Page/Page"
import { Table } from "components/Table"
import { useAuthenticate } from "hooks/useAuthenticate"
import { useThrottledState } from "hooks/useThrottledState"
import { RoleType } from "roles"
import { RootState } from "services/store"
import { MultiRes } from "types/API"
import { Media, MediaType } from "types/Content"

export function Requests() {
  const { me } = useAuthenticate()
  const { role = 999, id } = me || {}

  const [page, onChangePage] = useState(1)
  const [limit, onChangeLimit] = useState(20)
  const [response, setResponse] = useState<MultiRes<Media<any>>>()
  const [isFetching, setIsFetching] = useState(false)
  const [view, setView] = useState<"my" | "all">("my")
  const [throttledSearchValue, searchValue, setSearchValue] = useThrottledState<string>("")
  const [type, setType] = useState<MediaType | "all">("all")

  const queries = useMemo(
    () => ({
      userId: view === "my" ? id : undefined,
      search: throttledSearchValue || undefined,
      type: type === "all" ? undefined : type,
      page,
      limit,
    }),
    [id, limit, page, role, throttledSearchValue, type, view],
  )

  const fetch = useCallback(async () => {
    setIsFetching(true)
    const response = await request.medias.getRequests(queries)
    setResponse(response)
    setIsFetching(false)
  }, [queries])

  useEffect(() => void fetch(), [queries, fetch])

  const medias = useSelector((state: RootState) => state.medias.medias)

  return (
    <Page>
      <div className="fullWidth flex row spaceBetween alignCenter" style={{ height: 50 }}>
        <Nav activeKey={type} onSelect={(v) => setType(v)} style={{ marginRight: 10 }}>
          <Nav.Item eventKey="all">Films et séries</Nav.Item>
          <Nav.Item eventKey="movie">Films</Nav.Item>
          <Nav.Item eventKey="serie">Séries</Nav.Item>
        </Nav>

        <Nav activeKey={view} onSelect={(v) => setView(v)}>
          <Nav.Item eventKey="my">Mes requêtes</Nav.Item>
          {role <= RoleType.ADMIN ? <Nav.Item eventKey="all">Toutes les requêtes</Nav.Item> : null}
        </Nav>

        <div className="flex row noWrap alignCenter">
          {isFetching && <Loader className="marginRight10" />}
          <Input
            onChange={setSearchValue}
            value={searchValue}
            placeholder="Rechercher un film ou une serie"
            style={{ width: 300 }}
          />
          {role <= RoleType.ADMIN ? (
            <Button
              className="marginLeft10"
              appearance="primary"
              onClick={request.medias.reImportAllRequests}
            >
              Reset requests
            </Button>
          ) : (
            <div />
          )}
        </div>
      </div>
      <div className="fullWidth fullHeight" style={{ height: "calc(100% - 50px)" }}>
        <Table
          data={response?.results.map((item) => medias[item.tmdbId]) || []}
          wordWrap={true}
          rowHeight={150}
          columns={[
            {
              grow: 1,
              title: "Titre",
              dataKey: "title",
              accessor: (_) => _,
              cell: CellContent,
              sortable: true,
              cellStyle: { padding: 0, minWidth: "100%" },
            },
          ]}
          fillHeight
          renderEmpty={() => (
            <div className="fullWidth fullHeight flex center alignCenter">Aucune requête</div>
          )}
          pagination={response}
          onChangePage={onChangePage}
          onChangeLimit={onChangeLimit}
        />
      </div>
    </Page>
  )
}
